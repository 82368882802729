import React, { useEffect, useState } from "react";
import "../App.css";
import Banner from "../Banner";
import axiosInstance from "../apiHandler";
import { useParams, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import FoodCategories from "../components/menuPageComponents/FoodCategories";
const Menue = () => {
  const [selectedCategory, selectCategory] = useState("All");
  const [selectedType, setSelectedType] = useState("");
  const [hotelDetails, setHotelDetails] = useState({});
  const { id } = useParams();
  const location = useLocation();

  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };
  const queryParams = getQueryParams();

  useEffect(() => {
    loadHotelData();
  }, []);
  async function loadHotelData() {
    const res = await axiosInstance.get("hotels/" + id);
    setHotelDetails(res.data);
  }
  return (
    <div
      style={{
        position: "absolute",
        height: "calc( 100% )",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <div style={{ flex: 1, overflow: "scroll" }}>
        {hotelDetails?.menu?.hotel && (
          <Banner hotel={hotelDetails?.menu?.hotel} />
        )}

        {hotelDetails?.menu?.hotel && (
          <FoodCategories
            selectedCategory={selectedCategory}
            selectCategory={selectCategory}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            categories={hotelDetails?.menu?.items
              .map((item) => item.category)
              .filter((value, index, array) => {
                return array.indexOf(value) === index;
              })}
            showFilter={queryParams.get("showFilter")}
          />
        )}
        {hotelDetails?.menu?.hotel && (
          <MenuItems
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            selectedCategory={selectedCategory}
            selectCategory={selectCategory}
            menuItems={hotelDetails?.menu?.items}
            showAmount={queryParams.get("showAmount")}
          />
        )}
      </div>
      <Footer />
    </div>
  );
  return (
    <div
      style={{
        position: "absolute",
        height: "calc( 100% )",
        width: "100%",
        overflow: "hidden",
        display: "flex",
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "scroll",
        }}
      >
        <div>
          {/* <HotelCard hotel={hotel} /> */}
          {hotelDetails?.menu?.hotel && (
            <Banner hotel={hotelDetails?.menu?.hotel} />
          )}
          {/* {JSON.stringify(hotelDetails?.menu?.hotel)} */}
        </div>
        <div
          style={{
            flex: 1,
            overflow: "scroll",

            display: "flex",
            flexDirection: "column",
          }}
        >
          <FoodCategories
            selectedCategory={selectedCategory}
            selectCategory={selectCategory}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            categories={hotelDetails?.menu?.items
              .map((item) => item.category)
              .filter((value, index, array) => {
                return array.indexOf(value) === index;
              })}
          />
          <MenuItems
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            selectedCategory={selectedCategory}
            selectCategory={selectCategory}
            menuItems={hotelDetails?.menu?.items}
            showAmount={queryParams.get("showAmount")}
          />
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Menue;

const MenuItems = ({
  selectedCategory,
  selectCategory,
  selectedType,
  setSelectedType,
  menuItems = [],
  showAmount = true,
}) => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      padding: "10px",
      //   maxHeight: "600px",
      flex: 1,
      overflow: "scroll", // Enable vertical scrolling
      gap: "15px", // Space between cards
      background: "#f0f0f0",
      marginBottom: 40,
    },
    card: {
      display: "flex",
      border: "1px solid #ddd",
      borderRadius: "10px",
      overflow: "hidden",
      minHeight: 140,
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      background: "#fff",
    },
    image: {
      width: "120px",
      height: "calc( 100% - 8px )",
      objectFit: "cover",
      border: "4px solid #fff",
      borderRadius: 10,
    },
    info: {
      padding: "10px",
      display: "flex",
      flexDirection: "column",
      // justifyContent: "space-between",
    },
    title: {
      fontSize: "18px",
      fontWeight: "600",
      marginBottom: "5px",
    },
    description: {
      fontSize: "14px",
      color: "#555",
      marginBottom: "10px",
    },
    price: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#333",
    },
    chips: {
      display: "flex",
      gap: "10px",
      marginTop: "8px",
    },
    chip: {
      padding: "5px 10px",
      fontSize: "12px",
      borderRadius: "15px",
      display: "inline-block",
      color: "#fff",
      fontWeight: "500",
    },
    vegChip: {
      backgroundColor: "#4caf50", // Green for vegetarian
    },
    nonVegChip: {
      backgroundColor: "#f44336", // Red for non-vegetarian
    },
    categoryChip: {
      backgroundColor: "#2196f3", // Blue for category
    },
  };
  const data = menuItems
    .filter((d) => {
      if (!selectCategory || selectedCategory.toLocaleLowerCase() === "all")
        return true;
      console.log(d.category.toLocaleLowerCase(), selectedCategory);

      return (
        d.category.toLocaleLowerCase() === selectedCategory.toLocaleLowerCase()
      );
    })
    .filter((d) => {
      if (!selectedType) return true;
      if (selectedType === "veg") return d.veg === true;
      else return d.veg === false;
    });
  return (
    <div style={styles.container}>
      {data.map((item, index) => (
        <div key={index} style={styles.card}>
          <img
            src={
              item.image ||
              "https://grandseasonscoquitlam.com/img/placeholders/comfort_food_placeholder.png?v=1"
            }
            alt={item.title}
            style={styles.image}
          />
          <div style={styles.info}>
            <div>
              <div style={styles.title}>{item.title}</div>
              <div style={styles.description}>
                {trimString(item.description)}
              </div>
              {!(showAmount === "false") && (
                <div style={styles.price}>₹{item.price?.toFixed(2)}</div>
              )}
            </div>
            <div style={styles.chips}>
              {/* <div
                style={{
                  ...styles.chip,
                  ...(item.veg ? styles.vegChip : styles.nonVegChip),
                }}
              >
                {item.veg ? "Veg" : "Non-Veg"}
              </div> */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={require(item.veg
                    ? "../assets/foodImages/veg.png"
                    : "../assets/foodImages/nonveg.png")}
                  style={{ margin: 5 }}
                  height={20}
                />
                <div
                  style={{
                    fontSize: 10,
                    color: item.veg ? "rgb(55 143 60)" : "rgb(191 55 13)",
                  }}
                >
                  {item.veg ? "Veg" : "Non-Veg"}
                </div>
              </div>
              <div
                style={{
                  color: "#444",
                  fontSize: 10,
                  border: "1px solid #2196f3",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#2196f3",
                  color: "#fff",
                  // minWidth: 80,
                  borderRadius: 20,
                  fontWeight: 700,
                  letterSpacing: 1,
                  maxHeight: 25,
                  padding: "5px 10px",
                }}
              >
                {item.category}
              </div>
            </div>
          </div>
        </div>
      ))}

      {data.length === 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 20,
            fontWeight: 700,
            // border: "1px solid red",
            padding: 20,
          }}
        >
          {`No result found for "${selectedCategory}"!`}
        </div>
      )}
    </div>
  );
};

function trimString(inputString) {
  return inputString?.length > 50
    ? inputString.substring(0, 50) + "..."
    : inputString;
}

const ToggleSwitch = () => {
  const [isOn, setIsOn] = useState(false);

  const handleToggle = () => {
    setIsOn((prevState) => !prevState);
  };

  return (
    <div>
      <label>
        <input type="checkbox" checked={isOn} onChange={handleToggle} />
        {isOn ? "ON" : "OFF"}
      </label>
    </div>
  );
};
